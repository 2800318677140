
import { computed, defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useAuthenticationStore } from "@/store/pinia/application/useAuthenticationStore";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "error-404",
  components: {},
  setup() {
    const store = useStore();
    const authStore = useAuthenticationStore();
    const dashboardName = computed(() => {
      return authStore.activeSession.dashboardName;
    });

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });

    return {
      dashboardName,
    };
  },
});
